var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title":
                this.type == "edit"
                  ? _vm.$t("cip.plat.sys.post.title.editHeadTitle")
                  : _vm.$t("cip.plat.sys.post.title.addHeadTitle"),
            },
            on: {
              "head-save": function ($event) {
                return _vm.headSave("save")
              },
              "head-save-back": function ($event) {
                return _vm.headSave("back")
              },
              "head-cancel": _vm.headCancel,
            },
          }),
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.column, dataForm: _vm.dataObj },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataObj = $event
              },
              "update:data-form": function ($event) {
                _vm.dataObj = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }