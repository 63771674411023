<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        :head-title="this.type == 'edit' ? $t('cip.plat.sys.post.title.editHeadTitle') : $t('cip.plat.sys.post.title.addHeadTitle')"
        @head-save="headSave('save')"
        @head-save-back="headSave('back')"
        @head-cancel="headCancel">
        </head-layout>
      <!-- <form-layout ref="formLayout" icon="" :head-title="headtitle" page-type="self" :option="option"
      :btn-options="btnOptions" @form-btn-save-click="save" :dataForm="form"></form-layout>-->
      <form-layout :column="column" :dataForm.sync="dataObj" ref="formLayout"></form-layout>
    </el-main>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";

import {

  update,
  add,
  getDetail
} from "@/api/system/post";
export default {
  components: { FormLayout, HeadLayout },
  computed: {
    ...mapGetters(['language'])
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0,1)==" " || val2.substring(val2.length-1)==" ") {
          callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
        }
        if (val2.length > 60) {
          callback(new Error(this.$t('cip.cmn.rule.nameLength6Warning')));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.plat.sys.post.field.postName")+this.$t('cip.cmn.rule.noEmptyWarning')));
      }
    };
    return {
      type: '',
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
      {
        label: this.$t('cip.cmn.btn.saveBackBtn'),
        emit: "head-save-back",
        type: "button",
        icon: ""
      }, {
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: "head-cancel",
        type: "button",
        icon: ""
      }],
      dataObj: {

      },
      column: [
        {
          label: this.$t("cip.plat.sys.post.field.tenantId"),
          prop: "tenantId",
          search: true,
          type: "tree",
          dicUrl: "/api/sinoma-system/tenant/select",
          props: {
            label: "tenantName",
            value: "tenantId"
          },
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.post.field.tenantId"),
              trigger: "blur"
            }
          ]
        },
        {
          label: this.$t("cip.plat.sys.post.field.category"),
          prop: "category",
          type: "select",
          dicUrl: "/api/sinoma-system/dict/dictionary?code=post_category",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: "number",
          search: true,
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.post.field.category"),
            trigger: "blur"
          }]
        },
        {
          label: this.$t("cip.plat.sys.post.field.postCode"),
          prop: "postCode",
          align: "right",
          maxlength:12,
          // isGlobal: true,
          disabled: false,
          showWordLimit: true,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.post.field.postCode"),
              trigger: "blur"
            },
          ]
        },
        {
          label: this.$t("cip.plat.sys.post.field.postName"),
          prop: "postName",
          search: true,
          align: "center",
          cell: true,
          isGlobal:true, //是否支持国际化
          rules: [{ required: true, validator: validatePass, trigger: ['blur','change'] }]
        },
        {
          label: this.$t("cip.plat.sys.post.field.isEnable"),
          prop: "isEnable",
          type: "select",
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: "number",
          search: true,
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.post.field.isEnable"),
            trigger: "blur"
          }]
        },
        {
          label: this.$t("cip.plat.sys.post.field.sort"),
          prop: "sort",
          row: true,
          type: "number",
          minRows:0,
          maxRows:1000000000,
          align: "right",
          cell: true,
          width: 100,
          rules: [
            {
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.sys.post.field.sort`),
              trigger: "blur",
            },
          ],
        },

        {
          label: this.$t("cip.plat.sys.post.field.remark"),
          span:16,
          row: true,
          prop: "remark",
          hide: true,
          type: "textarea",
          maxlength: 200,
          showWordLimit: true,
        }
      ]
    }
  },
  created() {

    if(this.$route.query.tenantStatus != "true"){
      this.findObject(this.column, "tenantId").display = false
      this.dataObj.tenantId = "000000";
    }

    this.type = this.$route.query.type
    // console.log(this.$route.query)
    if (this.$route.query.type == 'edit') {
      this.column[2].disabled = true
      // console.log(decodeURIComponent(this.$route.query.data))
      // this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
      getDetail(this.$route.query.id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
        this.findObject(this.$refs.formLayout.option.column, "postCode").disabled = true

      })
    } else {

    }
  },
  mounted() { },

  methods: {
    headSave(type) {
      this.dataObj.postCode=this.dataObj.postCode.trim()
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading()
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(this.dataObj).then((res) => {
            if (type == 'back') {
              this.close()
            }
            this.dataObj = res.data.data
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            console.log(res.data.data.id,"res.data.data.id")
            this.$refs.formLayout.$refs.form.allDisabled = false
            this.findObject(this.$refs.formLayout.option.column, "postCode").disabled = true
          }, error => {
            this.$refs.formLayout.$refs.form.allDisabled = false
            window.console.log(error);

          }).finally(()=>{
            this.$loading().close()
          })
        } else {
          this.$loading().close()
          this.$refs.formLayout.$refs.form.allDisabled = false
        }


      })

    },
    init(row){
        getDetail(row).then(res => {
          const {data} = res;
          this.dataObj = data.data;
        })
      },
    initData() {
      getDeptTree().then(res => {
        const column = this.findObject(this.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
